.body {
	padding: 0 32px 25px;
}

.header {
	padding: 16px 32px;
	position: relative;
}

.title {
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
}


.modalHeader {
    align-items: flex-start;
    padding: 24px 24px 0;
    &.sm {
        padding: 16px 16px 0;
    }
    .mantine-Modal-close{

    }
}

.modalTitle {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: var(--primary-black);
    &.sm {
        font-size: 20px;
        line-height: 28px;
    }
}

.modalCloseBtn {
    color: var(--grey-4);
    width: 40px;
    height: 40px;
    margin-top: -5px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 480px;
    border-radius: 16px;
    &.sm {
        gap: 16px;
        min-width: 400px;
    }
}

.modalBody {
    padding: 0 24px 24px;
    &.sm {
        padding: 0 16px 16px;
    }
}

@mixin smaller-than $mantine-breakpoint-md {
    .modalBody {
        padding: 0 24px 24px;
    }
    .modalContent {
        min-width: auto;
    }
}